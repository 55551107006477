































































































































































































































































































































































































































































































































































































































































































































































































































import { debounceProcess } from "@/helpers/debounce";
import { generateUUID } from "@/helpers/uuid";
import {
  useFindAllWarehouseLocation,
  useFindMasterType,
  useLocalFilter,
  useMapLocationToOptions,
  useMapMasterTypeToOption,
} from "@/hooks";
import MNotificationVue from "@/mixins/MNotification.vue";
import { Option } from "@/models/class/option.class";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PropsModel } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Mode } from "@/models/enums/global.enum";
import { Messages } from "@/models/enums/messages.enum";
import {
  CustomListCategory,
  DataListAssetBook,
  FormFieldsResponseFormAssetCategoryById,
  ResponseListAssetAdditionDraft,
  ResponseListMasterAsset,
} from "@/models/interface/assets.interface";
import {
  ContactData,
  CustomAddressDataList,
  ResponseListContactData,
} from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { assetsServices } from "@/services/assets.service";
import { contactServices } from "@/services/contact.service";
import {
  changeCurrencytoNumeric,
  currencyFormat,
  numericOnly,
  numericOnlyv2,
} from "@/validator/globalvalidator";
import { UploadFile } from "ant-design-vue/types/upload";
import moment from "moment";
import printJs from "print-js";
import Vue from "vue";
import { assetAdditionServices } from "./services";
import { RequestAssetAdditions, ResponseAssetSingle } from "./types";

export default Vue.extend({
  name: "AdditionSingle",
  components: {
    VNodes: {
      functional: true,
      render: (_h, ctx) => ctx.props.vnodes,
    },
  },
  mixins: [MNotificationVue],
  data() {
    this.getListContact = debounceProcess(this.getListContact, 200);
    this.getListLocation = debounceProcess(this.getListLocation, 200);
    this.getListAssetCategories = debounceProcess(
      this.getListAssetCategories,
      200
    );
    this.getListAssetBook = debounceProcess(this.getListAssetBook, 200);
    this.getParentUnitCode = debounceProcess(this.getParentUnitCode, 200);
    this.getDataAssetTag = debounceProcess(this.getDataAssetTag, 200);
    return {
      Messages,
      DEFAULT_DATE_FORMAT,
      InputNumberOnly: numericOnly,
      formatCurrency: currencyFormat,
      formatCurrencytoNumber: changeCurrencytoNumeric,
      tempSupplier: {
        id: "",
        fullName: "",
      } as { id: string; fullName: string },
      stateUnitCode: "" as string,
      valueQr: [] as string[],
      visibleAdditional: false as boolean,
      visibleQr: false as boolean,
      disableDp: false as boolean,
      disableDpAmount: false as boolean,
      disableTax: false as boolean,
      disabledUnitCode: true as boolean,
      fileList: [] as UploadFile[],
      dataParentUnitCode: {} as ResponseListMasterAsset,
      urlForSend: "" as string,
      valueAddNew: "" as string,
      id: "" as string,
      idReferenceNumber: "" as string,
      idBook: "" as string,
      idContact: "" as string,
      mode: "" as string,
      loadingListAssetCategory: false as boolean,
      loadingListAssetBook: false as boolean,
      loadingData: false as boolean,
      loadingCustomerLocation: false as boolean,
      loadingLocation: false as boolean,
      loadingStatus: false as boolean,
      loadingParentUnitCode: false as boolean,
      loadingTag: false as boolean,
      dataCustomerLocation: [] as CustomAddressDataList[],
      dataCustomer: [] as ContactData[],
      dataSupplierDealer: [] as ContactData[],
      dataSupplierLeasing: [] as ContactData[],
      dataEmployee: [] as ContactData[],
      dataListLocation: [] as Option[],
      dataListCategory: [] as CustomListCategory[],
      dataListBook: [] as DataListAssetBook[],
      dataListStatus: [] as Option[],
      dataForUpdate: {} as ResponseListAssetAdditionDraft,
      dataType: [] as string[],
      dataModel: [] as string[],
      dataFuel: [] as string[],
      loading: false as boolean,
      depreciate: false as boolean | undefined,
      leasing: false as boolean,
      additionalForm: [] as FormFieldsResponseFormAssetCategoryById[],
      modelAdditionalForm: {} as any, // v-model additional form
      headers: {
        authorization: "Bearer " + this.$store.state.access_token,
        "Content-Type": "multipart/form-data",
      },
      form: this.$form.createForm(this, { name: "assetAdditions" }),
      formRules: {
        book: {
          label: "lbl_book",
          name: "book",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "book",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        category: {
          label: "lbl_category",
          name: "category",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "category",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        depreciationAccount: {
          label: "lbl_depreciation_account",
          name: "depreciationAccount",
          placeholder: "< auto generate dari category >",
          decorator: ["depreciationAccount"],
        },
        accumulatedDepreciationAccount: {
          label: "lbl_accumulated_depreciation_account",
          name: "accumulatedDepreciationAccount",
          placeholder: "< auto generate dari category >",
          decorator: ["accumulatedDepreciationAccount"],
        },
        description: {
          label: "lbl_description",
          name: "description",
          placeholder: "lbl_description_placeholder",
          decorator: [
            "description",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        parentUnitCode: {
          label: "lbl_unit_code_parent",
          name: "parentUnitCode",
          placeholder: "lbl_unit_code_parent_placeholder",
          decorator: ["parentUnitCode"],
        },
        unit: {
          label: "lbl_qty",
          name: "unit",
          placeholder: "lbl_qty_placeholder",
          decorator: [
            "unit",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        serialNumber: {
          label: "lbl_serial_number",
          name: "serialNumber",
          placeholder: "lbl_serial_number_placeholder",
          decorator: [
            "serialNumber",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        unitCode: {
          label: "lbl_unit_code",
          name: "unitCode",
          placeholder: "lbl_unit_code_placeholder",
          decorator: [
            "unitCode",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        location: {
          label: "lbl_location",
          name: "location",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "location",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        status: {
          label: "lbl_purchase_status",
          name: "status",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "status",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        type: {
          label: "lbl_type",
          name: "type",
          placeholder: "lbl_type_placeholder",
          decorator: [
            "type",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        capacity: {
          label: "lbl_capacity",
          name: "capacity",
          placeholder: "lbl_capacity_placeholder",
          decorator: ["capacity"],
        },
        salvageValue: {
          label: "lbl_salvage_value",
          name: "salvageValue",
          placeholder: "lbl_salvage_value_placeholder",
          decorator: ["salvageValue"],
        },
        attachment: {
          label: "lbl_attachment_file",
          name: "attachment",
          placeholder: "lbl_attachment_file_placeholder",
          decorator: [
            "attachment",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        invoiceNumber: {
          label: "lbl_invoice_number",
          name: "invoiceNumber",
          placeholder: "lbl_invoice_number_placeholder",
          decorator: [
            "invoiceNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        poNumber: {
          label: "lbl_po_number",
          name: "poNumber",
          placeholder: "lbl_po_number_placeholder",
          decorator: [
            "poNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        supplierName: {
          label: "lbl_supplier_name",
          name: "supplierName",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "supplierName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        customerName: {
          label: "lbl_customer_name",
          name: "customerName",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "customerName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        customerLocation: {
          label: "lbl_customer_location",
          name: "customerLocation",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "customerLocation",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        employeeName: {
          label: "lbl_employee_name",
          name: "employeeName",
          placeholder: "lbl_type_to_find_placeholder",
          decorator: [
            "employeeName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        model: {
          label: "lbl_model",
          name: "model",
          placeholder: "lbl_model_placeholder",
          decorator: [
            "model",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        assetCost: {
          label: "lbl_asset_cost_dpp",
          name: "assetCost",
          placeholder: "lbl_asset_cost_dpp_placeholder",
          decorator: [
            "assetCost",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
                {
                  validator: numericOnlyv2,
                },
              ],
            },
          ],
        },
        lifeMonths: {
          label: "lbl_life_months",
          name: "lifeMonths",
          placeholder: "lbl_life_months_placeholder",
          decorator: [
            "lifeMonths",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        acquisitionDate: {
          label: "lbl_acquisition_date",
          name: "acquisitionDate",
          placeholder: "lbl_acquisition_date_placeholder",
          decorator: [
            "acquisitionDate",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        leasing: {
          label: "lbl_leasing",
          name: "leasing",
          placeholder: "lbl_leasing_placeholder",
          decorator: [
            "leasing",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        depreciate: {
          label: "lbl_depreciate",
          name: "depreciate",
          placeholder: "Insert your Depreciate",
          decorator: ["depreciate"],
        },
        leasingNumber: {
          label: "lbl_leasing_number",
          name: "leasingNumber",
          placeholder: "lbl_leasing_number_placeholder",
          decorator: [
            "leasingNumber",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        leasingDate: {
          label: "lbl_leasing_date",
          name: "leasingDate",
          placeholder: "lbl_leasing_date_placeholder",
          decorator: [
            "leasingDate",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        downPayment: {
          label: "Down Payment (%)",
          name: "downPayment",
          placeholder: "Insert your Down Payment",
          decorator: [
            "downPayment",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        tax: {
          label: "Tax (%)",
          name: "tax",
          placeholder: "Insert your Tax",
          decorator: [
            "tax",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        leasingDPAmount: {
          label: "Down Payment (Amount)",
          name: "leasingDPAmount",
          placeholder: "Insert your Down Payment (Amount)",
          decorator: [
            "leasingDPAmount",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        leasorName: {
          label: "lbl_lessor_name",
          name: "leasorName",
          placeholder: "lbl_lessor_name_placeholder",
          decorator: [
            "leasorName",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        leasingPeriod: {
          label: "Leasing Period (Months)",
          name: "leasingPeriod",
          placeholder: "Insert your Leasing Period",
          decorator: [
            "leasingPeriod",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        totalLeasingCost: {
          label: "lbl_total_leasing_cost",
          name: "totalLeasingCost",
          placeholder: "lbl_total_leasing_cost_placeholder",
          decorator: [
            "totalLeasingCost",
            {
              rules: [
                {
                  required: false,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
                {
                  validator: numericOnlyv2,
                },
              ],
            },
          ],
        },
      },
    };
  },
  computed: {
    formItemLayout() {
      return {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
      };
    },
  },
  watch: {
    dataSupplierDealer: {
      immediate: true,
      deep: true,
      handler() {
        this.assignSupplier();
      },
    },
    tempSupplier: {
      immediate: true,
      deep: true,
      handler() {
        this.assignSupplier();
      },
    },
    dataListCategory: {
      immediate: true,
      deep: true,
      handler() {
        this.handleWatchListCategoryAndDataForUpdate();
      },
    },
    dataForUpdate: {
      immediate: true,
      deep: true,
      handler() {
        this.handleWatchListCategoryAndDataForUpdate();
      },
    },
  },
  created() {
    // set Mode
    this.mode = this.$route.meta.mode;
    this.getListAssetBook("");
    this.getListLocation();
    this.getListStatus();
    this.getParentUnitCode();
    let paramsTag = new RequestQueryParams();
    this.loadingTag = true;
    this.loadingData = true;
    const promise1 = contactServices.listContactData(
      new RequestQueryParams("supplierData.supplierType~Dealer_AND_active~true")
    );
    const promise2 = contactServices.listContactData(
      new RequestQueryParams("customer~true_AND_active~true")
    );
    const promise3 = contactServices.listContactData(
      new RequestQueryParams("employee~true_AND_active~true")
    );
    const promise4 = contactServices.listContactData(
      new RequestQueryParams(
        "supplierData.supplierType~Leasing_AND_active~true"
      )
    );
    const promiseType = assetsServices.getAssetTag(paramsTag, "type");
    const promiseModel = assetsServices.getAssetTag(paramsTag, "model");
    const promiseFuel = assetsServices.getAssetTag(paramsTag, "fuel");
    Promise.all([
      promise1,
      promise2,
      promise3,
      promise4,
      promiseType,
      promiseModel,
      promiseFuel,
    ])
      .then((data: PropsModel[]) => {
        const [
          supplierDealer,
          customer,
          employee,
          supplierLeasing,
          tagType,
          tagModel,
          tagFuel,
        ] = data;
        this.dataSupplierDealer = supplierDealer.data;
        this.dataCustomer = customer.data;
        this.dataEmployee = employee.data;
        this.dataSupplierLeasing = supplierLeasing.data;
        this.dataType = tagType.data;
        this.dataModel = tagModel.data;
        this.dataFuel = tagFuel.data;
      })
      .finally(() => {
        this.loadingData = false;
        this.loadingTag = false;
      });
  },
  mounted() {
    const uuid = generateUUID();
    this.form.setFieldsValue({
      salvageValue: 0,
      status: "New",
      serialNumber: `SPP-${uuid.substring(uuid.length - 5)}`,
    });
    switch (this.mode) {
      case Mode.EDIT:
        this.id = this.$route.params.id;
        this.getAssetAdditionDraft();
        break;
      case Mode.VIEW: // view asset details
        this.idReferenceNumber = decodeURIComponent(this.$route.params.id);
        this.getViewSingle();
        break;
      default:
        break;
    }
  },
  methods: {
    useLocalFilter,
    handleWatchListCategoryAndDataForUpdate() {
      if (
        this.dataListCategory.length > 0 &&
        this.dataForUpdate &&
        Object.keys(this.dataForUpdate).length > 0
      ) {
        this.handleChangeCategory(
          this.dataForUpdate.assetCategoryId,
          true,
          true
        );
      }
    },
    assignSupplier() {
      if (
        this.tempSupplier.id &&
        !this.dataSupplierDealer.some(
          dataSome => dataSome.id === this.tempSupplier.id
        )
      )
        this.dataSupplierDealer = [
          ...this.dataSupplierDealer,
          {
            fullName: this.tempSupplier.fullName,
            id: this.tempSupplier.id,
          },
        ];
    },
    getFormAssetCategoryById(idCategory, additionalProperties?) {
      assetsServices.listFormAssetCategoryById(idCategory).then(response => {
        this.additionalForm = response.formFields.map((data, index) => {
          return { ...data, key: index };
        });
        if (response.formFields.length < 1) {
          this.modelAdditionalForm = {};
        } else if (response.formFields.length > 0 && !additionalProperties) {
          const temp = {};
          response.formFields.forEach(dataForeach => {
            temp[dataForeach.propertyName] = dataForeach.defaultValue;
          });
          this.modelAdditionalForm = temp;
        }
        if (additionalProperties) {
          this.modelAdditionalForm = additionalProperties;
        }
      });
    },
    beforeUpload(file) {
      const isLt5M = file.size;
      if (isLt5M >= 5242880) {
        this.$notification.error({
          description: "File must smaller than 5MB!",
          message: "Error",
          duration: 30,
        });
      }
      return isLt5M;
    },
    addNew(type): void {
      let valuesTag = [] as string[];
      valuesTag.push(this.valueAddNew);
      if (this.valueAddNew) {
        let payload = {
          values: valuesTag,
        };
        switch (type) {
          case "type":
            payload["tagId"] = "type";
            break;
          case "model":
            payload["tagId"] = "model";
            break;
          case "fuel":
            payload["tagId"] = "fuel";
            break;
          default:
            break;
        }
        assetsServices.addAssetTag(payload).then((data: any) => {
          switch (type) {
            case "type":
              data.forEach(element => {
                this.dataType.push(element);
              });
              break;
            case "model":
              data.forEach(element => {
                this.dataModel.push(element);
              });
              break;
            case "fuel":
              data.forEach(element => {
                this.dataFuel.push(element);
              });
              break;
            default:
              break;
          }
        });
      } else {
        switch (type) {
          case "type":
            this.$notification.error({
              message: "Error",
              description: "Please Add Type Name First",
            });
            break;
          case "model":
            this.$notification.error({
              message: "Error",
              description: "Please Add Model Name First",
            });
            break;
          case "fuel":
            this.$notification.error({
              message: "Error",
              description: "Please Add Fuel Name First",
            });
            break;
          default:
            break;
        }
      }
    },
    handleOkQr() {
      this.visibleQr = false;
      const params: RequestQueryParamsModel = {
        unitCodes: this.stateUnitCode,
      };
      assetsServices.getPrintQr(params).then(response => {
        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          printJs(url);
        }
      });
    },
    handleGenerateQr() {
      if (this.id) {
        this.visibleQr = true;
        this.valueQr = [this.stateUnitCode];
      }
    },
    handleTaxAndChangeDp(value) {
      if (value) {
        this.form.setFieldsValue({
          leasingDPAmount: undefined,
        });
        this.disableDpAmount = true;
        this.formRules.leasingDPAmount.decorator = [
          "leasingDPAmount",
          {
            rules: [
              {
                required: false,
                message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
              },
            ],
          },
        ];
      } else {
        this.disableDpAmount = false;
        this.formRules.downPayment.decorator = [
          "downPayment",
          {
            rules: [
              {
                required: true,
                message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
              },
            ],
          },
        ];
        this.formRules.leasingDPAmount.decorator = [
          "leasingDPAmount",
          {
            rules: [
              {
                required: true,
                message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
              },
            ],
          },
        ];
      }
    },
    handleChangeDpAmount(value) {
      if (value.target.value) {
        this.form.setFieldsValue({
          downPayment: undefined,
          tax: undefined,
        });
        this.disableDp = true;
        this.disableTax = true;
        this.formRules.downPayment.decorator = [
          "downPayment",
          {
            rules: [
              {
                required: false,
                message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
              },
            ],
          },
        ];
        this.formRules.tax.decorator = [
          "tax",
          {
            rules: [
              {
                required: false,
                message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
              },
            ],
          },
        ];
      } else {
        this.disableDp = false;
        this.disableTax = false;
        this.formRules.downPayment.decorator = [
          "downPayment",
          {
            rules: [
              {
                required: true,
                message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
              },
            ],
          },
        ];
        this.formRules.leasingDPAmount.decorator = [
          "leasingDPAmount",
          {
            rules: [
              {
                required: true,
                message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
              },
            ],
          },
        ];
        this.formRules.tax.decorator = [
          "tax",
          {
            rules: [
              {
                required: true,
                message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
              },
            ],
          },
        ];
      }
    },
    onBlur(value, form: string) {
      if (value.target.value && !this.form.getFieldError(`${form}`)) {
        let dataobj = {} as { [props: string]: string };
        dataobj[form] = this.formatCurrency(value.target.value);
        this.form.setFieldsValue(dataobj);
      }
    },
    onFocus(value, form: string) {
      if (value.target.value && !this.form.getFieldError(`${form}`)) {
        let dataobj = {} as { [props: string]: number };
        dataobj[form] = this.formatCurrencytoNumber(value.target.value);
        this.form.setFieldsValue(dataobj);
      }
    },
    handleCancel() {
      switch (this.mode) {
        case Mode.CREATE:
          this.fileList = [];
          this.urlForSend = "";
          this.form.resetFields();
          this.form.setFieldsValue({
            salvageValue: 0,
            status: "New",
          });
          break;
        case Mode.EDIT:
          this.$router.push("/assets/additions/read");
          break;
        default:
          break;
      }
    },
    getDataAssetTag(valueSearch, id: "type" | "model" | "fuel") {
      this.valueAddNew = valueSearch;
      let params = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;
      // id 1 = type
      // id 2 = model
      if (valueSearch) params.search = `value~*${valueSearch}*`;
      this.loadingTag = true;
      assetsServices
        .getAssetTag(params, id)
        .then(data => {
          switch (id) {
            case "type":
              this.dataType = data.data;
              break;
            case "model":
              this.dataModel = data.data;
              break;
            case "fuel":
              this.dataFuel = data.data;
              break;
            default:
              break;
          }
        })
        .finally(() => (this.loadingTag = false));
    },
    getViewSingle() {
      let params = {
        page: 0,
        limit: 10,
        search: `referenceNo~${this.idReferenceNumber}`,
      } as RequestQueryParamsModel;

      let paramsDetail = {
        page: 0,
        limit: 10,
      } as RequestQueryParamsModel;

      assetsServices.listAssetAdditions(params).then(dataList => {
        assetsServices
          .getDetailAssetAdditions(paramsDetail, dataList.data[0].id)
          .then(data => {
            const {
              id,
              assetBook,
              assetCategory,
              description,
              unit,
              serialNumber,
              unitCode,
              assetLocation,
              status,
              invoiceNumber,
              purchaseOrderNumber,
              customerLocation,
              model,
              assetCost,
              lifeMonths,
              acquisitionDate,
              leasing,
              depreciate,
              parentUnitCode,
              supplier,
              customerName,
              employeeName,
              leasingNumber,
              leasingDate,
              leasingPercent,
              leasingValue,
              leasorName,
              leasingPeriod,
              totalLeasingCost,
              type,
              capacity,
              salvageValue,
              fileAttachment,
              leasingTax,
            } = data;

            if (fileAttachment) {
              const splited = fileAttachment.split("/");
              this.fileList = [
                ...this.fileList,
                {
                  uid: "1",
                  name: splited[splited.length - 1],
                  status: "done",
                  url: fileAttachment,
                  size: 0,
                  type: "",
                },
              ];
            }

            this.stateUnitCode = unitCode;
            this.id = id;

            this.leasing = leasing;
            this.depreciate = depreciate;
            this.setDataForm({
              acquisitionDate: acquisitionDate,
              status,
              unit,
              unitCode,
              poNumber: purchaseOrderNumber,
              model,
              lifeMonths,
              category: assetCategory.category.id,
              assetCost,
              invoiceNumber,
              description,
              customerLocation,
              serialNumber,
              book: assetBook,
              capacity,
              customerName: customerName,
              employeeName: employeeName,
              supplierName: supplier,
              downPayment: leasingPercent,
              leasingDPAmount: leasingValue,
              salvageValue,
              type,
              parentUnitCode,
              depreciationAccount: `${assetCategory.depreciationExpenseAccount.code} ${assetCategory.depreciationExpenseAccount.description}`,
              accumulatedDepreciationAccount: `${assetCategory.accumulatedDepreciationAccount.code} ${assetCategory.accumulatedDepreciationAccount.description}`,
              location: `${assetLocation.warehouse.branchWarehouse.name} - ${assetLocation.warehouse.name} - ${assetLocation.name}`,
              leasingNumber,
              leasingDate,
              leasorName,
              leasingPeriod,
              totalLeasingCost,
              tax: leasingTax,
            });
          });
      });
    },
    getParentUnitCode(valueSearch = "") {
      const params = new RequestQueryParams();

      if (valueSearch) params.search = `unitCode~*${valueSearch}*`;
      this.loadingParentUnitCode = true;
      assetsServices
        .listMasterAsset(params)
        .then(data => {
          this.dataParentUnitCode = data;
        })
        .finally(() => (this.loadingParentUnitCode = false));
    },
    handleSelectBook(value) {
      this.idBook = value;
      this.getListAssetCategories("", value);
    },
    customRequest(options) {
      const data = new FormData();
      data.append("file", options.file);
      assetsServices
        .createUploadFile(data, "asset")
        .then(response => {
          options.onSuccess(options);
          this.urlForSend = response.url;
        })
        .catch(err => {
          options.onError(err, options);
        });
    },
    handleChange(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList = fileList.map(file => {
        if (file.response) {
          file.url = file.response.url;
        }
        return file;
      });
      this.fileList = fileList;
      if (info.file.status === "done") {
        this.pathFile = info.file.response.url;
        this.$notification.success({
          description: `${info.file.name} file uploaded successfully`,
          message: "Success",
          duration: 30,
        });
      } else if (info.file.status === "error") {
        this.$notification.error({
          description: `${info.file.name} file upload failed.`,
          message: "Error",
          duration: 30,
        });
      }
    },
    getListStatus() {
      this.loadingStatus = true;
      useFindMasterType("ASSET_PURCHASE_STATUS")
        .then(response => {
          this.dataListStatus = response.map<Option>(el => ({
            label: el.value,
            value: el.value,
            key: el.id,
          }));
        })
        .finally(() => (this.loadingStatus = false));
    },
    getListLocation(valueSearch = "") {
      const params = new RequestQueryParams();
      if (valueSearch) {
        params.search = `warehouse.branchWarehouse.name~*${valueSearch}*_OR_warehouse.branchWarehouse.code~*${valueSearch}*_OR_warehouse.branchWarehouse.address~*${valueSearch}*_OR_warehouse.name~*${valueSearch}*_OR_name~*${valueSearch}*`;
      }
      this.loadingLocation = true;
      useFindAllWarehouseLocation(params)
        .then(response => {
          this.dataListLocation = useMapLocationToOptions(response);
        })
        .finally(() => (this.loadingLocation = false));
    },
    handleChangeCustomer(idContact) {
      this.idContact = idContact;
      this.getDetailContact();
    },
    getDetailContact() {
      this.loadingCustomerLocation = true;
      contactServices
        .getContactData(this.idContact)
        .then(data => {
          this.dataCustomerLocation = data.addressDataList;
          this.dataCustomerLocation = this.dataCustomerLocation.filter(
            (dataFilter, index, self) =>
              index ===
              self.findIndex(t => t.address === dataFilter.address && t.shipTo)
          );
        })
        .finally(() => (this.loadingCustomerLocation = false));
    },
    getListContact(valueSearch, supplierOrCustomerOrEmployee, supplierType?) {
      let params = {
        limit: 10,
        page: 0,
      } as RequestQueryParamsModel;
      if (
        valueSearch &&
        supplierType &&
        supplierOrCustomerOrEmployee === "supplier"
      ) {
        params.search = `supplierData.supplierType~*${supplierType}*_AND_${supplierOrCustomerOrEmployee}~true_AND_active~true_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*_OR_secureId~*${valueSearch}*`;
      } else if (valueSearch) {
        params.search = `${supplierOrCustomerOrEmployee}~true_AND_active~true_AND_firstName~*${valueSearch}*_OR_secureId~*${valueSearch}*`;
      }
      this.loadingData = true;
      contactServices
        .listContactData(params)
        .then((res: ResponseListContactData) => {
          switch (supplierOrCustomerOrEmployee) {
            case "supplier":
              if (supplierType === "Dealer" && valueSearch) {
                this.dataSupplierDealer = res.data;
              } else if (supplierType === "Leasing" && valueSearch) {
                this.dataSupplierLeasing = res.data;
                this.dataSupplierLeasing = this.dataSupplierLeasing.filter(
                  (dataFilter, index, self) =>
                    index ===
                    self.findIndex(t => t.fullName === dataFilter.fullName)
                );
              }
              break;
            case "customer":
              this.dataCustomer = res.data;
              break;
            case "employee":
              this.dataEmployee = res.data;
              break;
            default:
              break;
          }
        })
        .finally(() => (this.loadingData = false));
    },
    handleChangeCategory(valueId, _vNodes, watch?) {
      if (
        this.dataForUpdate &&
        Object.keys(this.dataForUpdate).length > 0 &&
        !watch
      ) {
        this.dataForUpdate.assetCategoryId = valueId;
      }

      this.dataListCategory.forEach(data => {
        if (data.id === valueId) {
          if (data.idForView?.toLowerCase().match(/.rent$/)) {
            this.disabledUnitCode = false;
            this.formRules.unitCode.decorator = [
              "unitCode",
              {
                rules: [
                  {
                    required: true,
                    message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                  },
                ],
              },
            ];
          } else {
            this.disabledUnitCode = true;
            this.formRules.unitCode.decorator = [
              "unitCode",
              {
                rules: [
                  {
                    required: false,
                    message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                  },
                ],
              },
            ];
          }
          if (!watch) {
            this.getFormAssetCategoryById(data.idForView?.toLowerCase());
            this.depreciate = data.depreciation;
            this.form.setFieldsValue({
              depreciationAccount: data.depreciationAccount,
              accumulatedDepreciationAccount:
                data.accumulatedDepreciationAccountId,
              lifeMonths: data.lifeMonths,
            });
          }
        }
      });
    },
    mapIdCategories(idCategories): string[] {
      return idCategories.map(dataMap => {
        let temp = dataMap.split("");
        if (temp.length > 0) {
          temp[0] = temp[0].toUpperCase();
          dataMap = temp.join("");
        }
        return dataMap;
      });
    },
    setValueDepre(assetCategoryId, tempDataSegments): void {
      tempDataSegments.forEach(dataSegment => {
        if (dataSegment.id === assetCategoryId) {
          this.setDataForm({
            depreciationAccount: dataSegment.depreciationAccount,
            accumulatedDepreciationAccount:
              dataSegment.accumulatedDepreciationAccountId,
          });
        }
      });
    },
    checkAndReturnString(value): string {
      if (value) return value;
      else return "";
    },
    checkIdForView(data): string {
      if (data) return data.idForView;
      else return "";
    },
    getListAssetCategories(
      valueSearch,
      idBook,
      assetCategoryId?,
      additionalProperties?
    ) {
      if (!idBook) {
        this.$notification.error({
          message: "Error",
          description: "Select Book First",
        });
      } else {
        const params = {
          page: 0,
          limit: 10,
          search: `book.secureId~*${idBook}*_AND_book.type~commercial`,
        } as RequestQueryParamsModel;
        if (assetCategoryId) params.search += `_AND_secureId~*${valueSearch}*`;
        else if (valueSearch)
          params.search += `_AND_categoryId~*${valueSearch}*`;
        this.loadingListAssetCategory = true;
        assetsServices
          .listAssetCategory(params)
          .then(response => {
            let tempDataSegments = [] as CustomListCategory[];
            response.data.forEach(data => {
              const tempSegment = {
                id: data.id,
                accumulatedDepreciationAccountId: `${this.checkAndReturnString(
                  data.accumulatedDepreciationAccount.code
                )}-${data.accumulatedDepreciationAccount.description}`,
                depreciationAccount: `${this.checkAndReturnString(
                  data.depreciationExpenseAccount.code
                )}-${data.depreciationExpenseAccount.description}`,
                first: "",
                second: "",
                idForView: "",
                lifeMonths: data.lifeMonths,
                depreciation: data.depreciate,
              } as CustomListCategory;

              let idCategories = data.category.id.split(".");

              idCategories = this.mapIdCategories(idCategories);

              tempSegment.idForView = idCategories.join(".");
              tempDataSegments.push(tempSegment);
            });
            if (assetCategoryId) {
              this.setValueDepre(assetCategoryId, tempDataSegments);
            }
            this.dataListCategory = tempDataSegments;
            // set additional properties when update asset adiition
            if (additionalProperties && assetCategoryId) {
              const idForView = this.checkIdForView(this.dataListCategory[0]);
              this.getFormAssetCategoryById(
                idForView.toLowerCase(),
                additionalProperties
              );
            }
          })
          .finally(() => (this.loadingListAssetCategory = false));
      }
    },
    getListAssetBook(valueSearch) {
      const params = new RequestQueryParams("type~commercial_AND_active~true");
      if (valueSearch) {
        params.search += `_AND_name~*${valueSearch}*_OR_secureId~*${valueSearch}*`;
      }
      this.loadingListAssetBook = true;
      assetsServices
        .listAssetBook(params)
        .then(data => {
          this.dataListBook = data.data;
        })
        .finally(() => (this.loadingListAssetBook = false));
    },
    setDataForm(values): void {
      for (const key in values) {
        this.form.getFieldDecorator(key, {
          initialValue: values[key],
        });
      }
      this.form.setFieldsValue(values);
    },
    getAssetAdditionDraft() {
      assetsServices.listAssetAdditionsDraft(this.id).then(data => {
        this.dataForUpdate = data;
        this.depreciate = data.depreciate;
        this.idBook = data.assetBookId;
        this.stateUnitCode = data.unitCode;
        if (data.supplierId) {
          this.getListContact(data.supplierId, "supplier", "dealer");
          this.getListContact(data.supplierId, "supplier", "leasing");
        }
        if (data.employeeId) {
          this.getListContact(data.employeeId, "employee");
        }
        if (data.customerId) {
          this.getListContact(data.customerId, "customer");
        }
        this.getListAssetCategories(
          data.assetCategoryId,
          data.assetBookId,
          data.assetCategoryId,
          data.additionalProperties
        );
        this.getListAssetBook(data.assetBookId);
        const params = new RequestQueryParams(`secureId~${data.locationId}`);
        this.loadingLocation = true;
        useFindAllWarehouseLocation(params)
          .then(response => {
            const { data } = response;
            const [location] = data;
            const flag = !!this.dataListLocation.find(
              item => item.value === (location && location.id)
            );
            if (!flag) {
              this.dataListLocation = [
                ...this.dataListLocation,
                ...useMapLocationToOptions(response),
              ];
            }
          })
          .finally(() => (this.loadingLocation = false));
        this.leasing = data.leasing;
        this.depreciate = data.depreciate;
        if (data.fileAttachment) {
          const splited = data.fileAttachment.split("/");
          this.fileList = [
            ...this.fileList,
            {
              uid: "1",
              name: splited[splited.length - 1],
              status: "done",
              url: data.fileAttachment,
              size: 0,
              type: "",
            },
          ];
        }
        this.tempSupplier = {
          fullName: data.supplierName,
          id: data.supplierId,
        };

        if (data.leasingPercent) {
          this.disableDpAmount = true;
        }

        if (data.leasingValue) {
          this.disableDp = true;
          this.disableTax = true;
        }

        this.setDataForm({
          type: data.type,
          acquisitionDate: data.acquisitionDate,
          capacity: data.capacity,
          leasingDPAmount: data.leasingValue
            ? this.formatCurrency(data.leasingValue)
            : null,
          unit: data.unit,
          unitCode: data.unitCode, // unitcode = tagnumber
          location: data.locationId,
          poNumber: data.purchaseOrderNumber,
          supplierName: data.supplierId,
          model: data.model,
          lifeMonths: data.lifeMonths,
          leasing: data.leasing,
          category: data.assetCategoryId,
          assetCost: data.assetCost
            ? this.formatCurrency(data.assetCost)
            : null,
          invoiceNumber: data.invoiceNumber,
          employeeName: data.employeeId,
          description: data.description,
          book: data.assetBookId,
          parentUnitCode: data.parentUnitCode,
          // assetNo,
          customerLocation: data.customerLocation,
          customerName: data.customerId,
          serialNumber: data.serialNumber,
          leasingNumber: data.leasingNumber,
          leasingDate: moment(data.leasingDate, DEFAULT_DATE_FORMAT),
          downPayment: data.leasingPercent,
          leasorName: data.leasorName,
          leasingPeriod: data.leasingPeriod,
          totalLeasingCost: data.totalLeasingCost
            ? this.formatCurrency(data.totalLeasingCost)
            : null,
          tax: data.leasingTax,
        });
      });
    },
    handleNotif(response: ResponseAssetSingle) {
      this.showNotifSuccess("lbl_asset_number_notif", {
        assetNumber: response.assetNo,
      });
      this.showNotifSuccess("lbl_unit_code_notif", {
        unitCode: response.unitCode,
      });
      this.showNotifSuccess("notif_create_success");
    },
    checkDataAndReturnNull(data) {
      if (data) return data;
      else return null;
    },
    checkAdditionalForm() {
      return this.additionalForm.find(
        dataFind =>
          dataFind.required && !this.modelAdditionalForm[dataFind.propertyName]
      );
    },
    validationAdditionSingle() {
      let check = true;
      if (this.additionalForm.length > 0 && this.checkAdditionalForm()) {
        this.showNotifError("lbl_validation_additional_single");
      } else {
        check = false;
      }
      return check;
    },
    submitForm(draftOrPosting) {
      this.form.validateFields((err, res) => {
        if (err) return;
        // cek additional form
        if (!this.validationAdditionSingle()) {
          // additional form empty
          const payload = {
            acquisitionDate: res.acquisitionDate,
            status: res.status,
            single: true, // karena single addition
            unit: res.unit,
            unitCode: res.unitCode, // unitcode = tagnumber
            locationId: res.location,
            purchaseOrderNumber: res.poNumber,
            model: res.model,
            lifeMonths: res.lifeMonths,
            leasing: this.leasing,
            assetCategoryId: res.category,
            assetCost: this.formatCurrencytoNumber(res.assetCost),
            invoiceNumber: res.invoiceNumber,
            description: res.description,
            depreciate: this.depreciate,
            customerLocation: this.checkDataAndReturnNull(res.customerLocation),
            serialNumber: res.serialNumber,
            assetBookId: res.book,
            capacity: res.capacity,
            customerId: this.checkDataAndReturnNull(res.customerName),
            employeeId: this.checkDataAndReturnNull(res.employeeName),
            supplierId: this.checkDataAndReturnNull(res.supplierName),
            fileAttachment: this.urlForSend, // belum
            leasingPercent: res.downPayment,
            salvageValue: res.salvageValue,
            totalLeasingCost: this.formatCurrencytoNumber(res.totalLeasingCost),
            leasingValue: this.formatCurrencytoNumber(res.leasingDPAmount),
            leasingTax: res.tax,
            type: res.type,
            leasorName: res.leasorName,
            leasingPeriod: res.leasingPeriod,
            parentUnitCode: res.parentUnitCode,
            leasingNumber: res.leasingNumber,
            leasingDate: res.leasingDate,
            additionalProperties: this.modelAdditionalForm,
          } as RequestAssetAdditions;
          switch (draftOrPosting) {
            case "draft":
              // baru create yang draft
              switch (this.mode) {
                case Mode.CREATE:
                  this.loading = true;
                  assetAdditionServices
                    .createAssetAdditionsDraft(payload)
                    .then(response => {
                      this.handleNotif(response);
                    })
                    .catch(() => this.showNotifError("notif_create_fail"))
                    .finally(() => (this.loading = false));
                  break;
                case Mode.EDIT:
                  payload.receiveNumber = this.dataForUpdate.receiveNumber;
                  payload.receiveItemId = this.dataForUpdate.receiveItemId;
                  this.loading = true;
                  assetAdditionServices
                    .createAssetAdditionsDraftWithId(payload, this.id)
                    .then(response => {
                      this.handleNotif(response);
                    })
                    .catch(() => this.showNotifError("notif_create_fail"))
                    .finally(() => (this.loading = false));
                  break;
                default:
                  break;
              }
              break;
            case "posting":
              // create
              switch (this.mode) {
                case Mode.CREATE:
                  this.loading = true;
                  assetAdditionServices
                    .createAssetAdditions(payload)
                    .then(response => {
                      this.handleNotif(response);
                      this.form.resetFields();
                      const uuid = generateUUID();
                      this.form.setFieldsValue({
                        salvageValue: 0,
                        status: "New",
                        serialNumber: `SPP-${uuid.substring(uuid.length - 5)}`,
                      });
                    })
                    .catch(() => this.showNotifError("notif_create_fail"))
                    .finally(() => (this.loading = false));
                  break;
                // update
                case Mode.EDIT:
                  payload.receiveNumber = this.dataForUpdate.receiveNumber;
                  payload.receiveItemId = this.dataForUpdate.receiveItemId;
                  this.loading = true;
                  assetAdditionServices
                    .updateAssetAdditions(payload, this.id)
                    .then(response => {
                      if (response.unitCode) {
                        this.showNotifSuccess("lbl_unit_code_notif", {
                          unitCode: response.unitCode,
                        });
                      }
                      this.showNotifSuccess("notif_update_success");
                      this.form.resetFields();
                      this.$router.push("/assets/additions/read");
                    })
                    .catch(() => this.showNotifError("notif_update_fail"))
                    .finally(() => (this.loading = false));
                  break;
                default:
                  break;
              }
              break;
            default:
              break;
          }
        }
      });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
});
